export const mytripData = [
  {
    id: 1,
    nom: "Liste des voyages",
    img: "./assets/img/ScreenshotMytrip1.jpg",
  },
  {
    id: 2,
    nom: "Descriptif d'un voyage",
    img: "./assets/img/ScreenshotMytrip4.jpg",
  },
  {
    id: 3,
    nom: "Liste des frais",
    img: "./assets/img/ScreenshotMytrip3.jpg",
  },
  {
    id: 4,
    nom: "Frais",
    img: "./assets/img/ScreenshotMytrip2.jpg",
  },
  {
    id: 5,
    nom: "Check List",
    img: "./assets/img/ScreenshotMytrip5.jpg",
  },
];

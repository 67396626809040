import React from "react";
//import s from "./style.module.css";
const MAX_TITLE_CHAR = 20;

function ScreenListItem({ item, onClick }) {
  const handleClick = () => {
    onClick(item);
  };

  return (
    <div onClick={handleClick} className="screenListItem_container">
      <img alt={item.nom} src={item.img} className="img" />
    </div>
  );
}

export default ScreenListItem;

import React, { useState, useEffect } from "react";

const DynamicText3 = ({ array }) => {
  const [words, setWords] = useState([]);

  useEffect(() => {
    setWords(
      array.map((word, index) => (
        <div
          key={index}
          style={{
            transform: `translateZ(-500px)`,
            opacity: 1,
          }}
        >
          {word}
        </div>
      ))
    );

    // On anime tous les mots en les faisant apparaître
    // et en augmentant leur profondeur d'affichage
    setTimeout(() => {
      words.forEach((word) => {
        const currentWord = words.find((w) => w.key === word);

        currentWord.style.transform = `translateZ(0px)`;
        currentWord.style.opacity = 1;
      });
    }, 1000);
  }, [array]);

  return (
    <div className="dynamic-text-container">
      {words.map((word, index) => (
        <div key={index} className="dynamic-text-word">
          {word}
        </div>
      ))}
    </div>
  );
};

export default DynamicText3;

import { useEffect, useState } from "react";
import Buttons from "../components/Buttons";
import Logo from "../components/Logo";
import Mouse from "../components/Mouse";
import Navigation from "../components/Navigation";
import ScreenList from "../components/ScreenList";
import ScreenMain from "../components/ScreenMain";
import { mytripData } from "../data/mytripData";

const Project5Detail = () => {
  const [currentScreen, setCurrentScreen] = useState(mytripData[0]);

  function updateCurrentScreen(screen) {
    setCurrentScreen(screen);
  }

  return (
    <div className="projet_detail_container">
      <Mouse />
      <Navigation />
      <Logo />
      <ScreenMain screen={currentScreen} />
      <ScreenList data={mytripData} onClickItem={updateCurrentScreen} />
      <Buttons left={"/projet-4"} right={"/contact"} />
    </div>
  );
};

export default Project5Detail;

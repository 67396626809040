export const projectsData = [
  {
    id: 1,
    title: "MyTrip",
    date: "Mars 2023",
    languages: ["React", "FastAPI", "Docker"],
    infos:
      "MyTrip est une application web qui vous permet de visualiser vos voyages et vos dépenses. Cette application a été développée en React. FastAPI a été utilisé pour créer l'API robuste et sécurisée (OAuth2).",
    img: "./assets/img/projet-1.jpg",
    link: "https://mytrip.pelloquin.net",
  },
  {
    id: 2,
    title: "Crypto Explorer",
    date: "Juin 2023",
    languages: ["React", "Sass", "Docker"],
    infos:
      "Application web permettant de suivre l'évolution des crypto-monnaies à l'aide de graphiques interactifs. Cette application a été développée en React, SASS pour la conception du style, et Docker pour l'hébergement.",
    img: "./assets/img/projet-2.png",
    link: "https://crypto.pelloquin.net",
  },
  {
    id: 3,
    title: "Movies",
    date: "Juin 2023",
    languages: ["React", "Sass", "Docker"],
    infos:
      "Application web permettant de consulter des informations sur les films alimentée par l'API de themoviedb.org. Cette application a été développée en React, SASS pour la conception du style, et Docker pour l'hébergement.",
    img: "./assets/img/projet-3.png",
    link: "https://cine.pelloquin.net",
  },
  {
    id: 4,
    title: "La montagne",
    date: "Août 2023",
    languages: ["HTML", "SASS"],
    infos:
      "Ce site de démonstration vous permet de découvrir mon savoir-faire en conception de site web.",
    img: "./assets/img/projet-4.jpg",
    link: "https://montagne.pelloquin.net",
  },
  {
    id: 5,
    title: "MyTrip Application Android",
    date: "Juillet 2020",
    languages: ["Windev Mobile", "PHP SlimFramwork"],
    infos:
      "MyTrip est une application Android qui vous permet de gérer vos voyages. Que vous soyez un voyageur occasionnel ou un globe-trotter chevronné, Mytrip vous aide à vous organiser, à suivre vos dépenses de voyage de manière simple et élégante.",
    img: "./assets/img/projet-5.png",
    link: "http://www.google.com",
  },
];

import React from "react";
//import s from "./style.module.css";

const ScreenMain = ({ screen }) => {
  return (
    <div className="screenmain">
      <h1 className="title">{screen.nom}</h1>
      <div className="img">
        <img src={screen.img} alt={screen.nom} />
      </div>
    </div>
  );
};

export default ScreenMain;

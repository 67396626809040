import React, { useState } from "react";
import ScreenListItem from "./ScreenListItem";

function ScreenList({ data, onClickItem }) {
  return (
    <div>
      <div className="screenlist_container">
        {data.map((item) => {
          return (
            <span className="screen_item" key={item.id}>
              <ScreenListItem key={item.id} item={item} onClick={onClickItem} />
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default ScreenList;
